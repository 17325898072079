import { IExplorerSettingsApi, IExplorerSettingsPayload } from '../const';

export function mapExplorerSettingsFromApi(
  data: IExplorerSettingsApi,
): IExplorerSettingsPayload {
  return {
    addressIcon: data.NEXT_PUBLIC_VIEWS_ADDRESS_IDENTICON_TYPE,
    theme: data.NEXT_PUBLIC_COLOR_THEME_DEFAULT,
    navigation: data.NEXT_PUBLIC_NAVIGATION_LAYOUT,
    logo: data.NEXT_PUBLIC_NETWORK_LOGO,
    logoDark: data.NEXT_PUBLIC_NETWORK_LOGO_DARK,
    icon: data.NEXT_PUBLIC_NETWORK_ICON,
    iconDark: data.NEXT_PUBLIC_NETWORK_ICON_DARK,
    background: data.NEXT_PUBLIC_HOMEPAGE_HERO_BANNER_CONFIG?.background?.[0],
    backgroundDark:
      data.NEXT_PUBLIC_HOMEPAGE_HERO_BANNER_CONFIG?.background?.[1],
    textColor: data.NEXT_PUBLIC_HOMEPAGE_HERO_BANNER_CONFIG?.text_color?.[0],
    textColorDark:
      data.NEXT_PUBLIC_HOMEPAGE_HERO_BANNER_CONFIG?.text_color?.[1],
    ogImage: data.NEXT_PUBLIC_OG_IMAGE_URL,
  };
}
