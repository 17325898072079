import React, { JSX } from 'react';
import { AppModal } from '@ankr.com/raas-ui';
import { Box, Button, Typography } from '@mui/material';

import { DialogId } from '../../../../../common/actions/openDialog';
import { useGetAccountInfoQuery } from '../../../../../common/components/AccountInfoForm/api/getAccountInfo';
import { USER_SUPPORT_URL } from '../../../../../common/const/values';
import { useDialog } from '../../../../../common/hooks/useDialog';
import { useTranslation } from '../../../../../i18n';
import { rollupDeleteModalTranslation } from './translation';
import { useRollupDeleteModalStyles } from './useRollupDeleteModalStyles';

interface IRollupDeleteModalProps {
  rollupUuid?: string;
}

export function RollupDeleteModal({
  rollupUuid,
}: IRollupDeleteModalProps): JSX.Element {
  const { classes } = useRollupDeleteModalStyles();

  const { keys, t } = useTranslation(rollupDeleteModalTranslation);

  const {
    open: rollupDeleteModalOpen,
    handleClose: handleRollupDeleteModalClose,
  } = useDialog(DialogId.RollupDelete);

  const { data: accountInfoData } = useGetAccountInfoQuery();

  const supportUrlParams = new URLSearchParams();
  if (accountInfoData?.email) {
    supportUrlParams.append('customfield_10101', accountInfoData.email);
  }
  if (rollupUuid) {
    supportUrlParams.append('summary', `Delete rollup ${rollupUuid}`);
    supportUrlParams.append('description', `Delete rollup ${rollupUuid}`);
  }

  return (
    <AppModal
      open={rollupDeleteModalOpen}
      onClose={handleRollupDeleteModalClose}
      title={t(keys.title)}
      maxWidth="xs"
      titleCentered
      withoutCloseButton
      titleClassName={classes.modalTitle}
      classes={{ paper: classes.modalContent }}
    >
      <Typography variant="body2" className={classes.modalHint}>
        {t(keys.hint)}
      </Typography>
      <Box className={classes.modalButtons}>
        <Button
          size="large"
          href={`${USER_SUPPORT_URL}?${supportUrlParams.toString()}`}
          rel="noreferrer"
          target="_blank"
        >
          {t(keys.contactSupport)}
        </Button>
        <Button
          onClick={handleRollupDeleteModalClose}
          size="large"
          variant="outlined"
          fullWidth
        >
          {t(keys.close)}
        </Button>
      </Box>
    </AppModal>
  );
}
