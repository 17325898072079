import { Locale } from '../../../../../i18n';

export const explorerSettingsTranslation = {
  [Locale.en]: {
    title: 'Customize Explorer',
    subtitle: 'Configure the explorer elements to suit your app style.',
    theme: {
      label: 'Default color theme',
      tooltip: 'Preferred color theme of the app',
    },
    addressIcon: {
      label: 'Default address identicon',
    },
    navigation: {
      label: 'Navigation Layout',
      vertical: 'Vertical Menu',
      horizontal: 'Horizontal Menu',
    },
    logo: {
      label: 'Network Logo URL',
      tooltip: 'If not provided, placeholder will be shown',
      hint: 'The logo height should be 24px and width less than 120px.',
    },
    logoDark: {
      label: 'Network Logo URL (Dark Mode)',
      tooltip: 'If not provided, inverted regular logo will be used instead',
    },
    icon: {
      label: 'Network Icon URL',
      tooltip:
        'Network Icon, used as a replacement for regular network logo when nav bar is collapsed, if not provided, placeholder will be shown',
      hint: 'The icon size should be at least 60px by 60px.',
    },
    iconDark: {
      label: 'Network Icon URL (Dark Mode)',
      tooltip:
        'If not provided, inverted regular Logo Sign will be used instead',
    },
    searchBarBg: {
      label: 'Search Block Background Color',
      tooltip: 'Banner background',
      hint: 'Could be a solid color or gradient. The string should be a valid background CSS property value.',
    },
    searchBarBgDark: {
      label: 'Search Block Background Color (Dark Mode)',
    },
    searchBarColor: {
      label: 'Search Block Text Color',
      tooltip: 'Banner text color',
      hint: 'The string should be a valid color CSS property value.',
    },
    searchBarColorDark: {
      label: 'Search Block Text Color (Dark Mode)',
    },
    ogImage: {
      label: 'OG Image URL',
      hint: 'Minimum image size is 200×20pixels (recommended: 1200×600); maximum supported file size is 8 MB; 2:1 aspect ratio; supported formats: image/jpeg, image/gif, image/png.',
    },
    submit: 'Save',
    cancel: 'Cancel',
  },
};
