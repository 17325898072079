import { queryFnNotifyWrapper } from '@ankr.com/raas-ui';

import { cacheTags, Gateways, mainApi } from '../../api';
import { mapDeploymentFromApi } from '../../common/utils/mapDeploymentFromApi';
import { IRollupDeployed, IRollupDeployedApi } from '../../Rollup/RollupConst';

export const { useGetRollupDeploysQuery } = mainApi.injectEndpoints({
  endpoints: build => ({
    getRollupDeploys: build.query<IRollupDeployed[], void>({
      queryFn: queryFnNotifyWrapper<void, never, IRollupDeployed[]>(
        async () => {
          const { data }: { data: IRollupDeployedApi[] } =
            await Gateways.auth.api.get('/api/v1/deployments');

          const mappedData: IRollupDeployed[] = data.map(deployment =>
            mapDeploymentFromApi({ deployment }),
          );

          return { data: mappedData };
        },
      ),
      providesTags: [cacheTags.rollups],
    }),
  }),
});
