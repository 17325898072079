import { IStackItem, IStackItemApi } from '../../../RollupConst';

export function mapStackFromApi(stack: IStackItemApi): IStackItem {
  // TODO: remove it when the backend will provide keys for all DALs
  const keyFromIcon = stack.icon?.split('/').at(-1)?.split('.').at(0);

  return {
    description: stack.description,
    key: stack.key || keyFromIcon || '',
    title: stack.title,
    uuid: stack.uuid,
    comingSoon: stack.coming_soon,
    mainnetComingSoon: stack.mainnet_coming_soon,
  };
}

export function mapStacksFromApi(stacks: IStackItemApi[]): IStackItem[] {
  return stacks.map((stack: IStackItemApi) => mapStackFromApi(stack));
}
