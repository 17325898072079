import { queryFnNotifyWrapper } from '@ankr.com/raas-ui';
import BigNumber from 'bignumber.js';

import { cacheTags, Gateways, mainApi } from '../../../../../../api';

interface IGetPaymentHistoryProps {
  uuid?: string;
}

interface IBillingHistoryApi {
  // eslint-disable-next-line camelcase
  created_at: string;
  symbol: string;
  amount: number;
  uuid: string;
}

export interface IBillingHistoryData {
  createdAt: Date;
  symbol: string;
  amount: BigNumber;
  uuid: string;
}

export const { useGetPaymentHistoryQuery } = mainApi.injectEndpoints({
  endpoints: build => ({
    getPaymentHistory: build.query<
      IBillingHistoryData[],
      IGetPaymentHistoryProps
    >({
      queryFn: queryFnNotifyWrapper<
        IGetPaymentHistoryProps,
        never,
        IBillingHistoryData[]
      >(async ({ uuid }) => {
        if (!uuid) {
          throw new Error('Uuid is not available, please try again.');
        }

        const { data }: { data: IBillingHistoryApi[] } =
          await Gateways.auth.api.get(
            `/api/v1/deployments/${uuid}/payments_history`,
          );

        const mappedData: IBillingHistoryData[] = data.map(item => ({
          createdAt: new Date(item.created_at),
          symbol: item.symbol,
          amount: new BigNumber(item.amount),
          uuid: item.uuid,
        }));

        return { data: mappedData };
      }),
      providesTags: [cacheTags.rollups],
    }),
  }),
});
