import { makeStyles } from 'tss-react/mui';

import blockies from './assets/blockies.png';
import github from './assets/github.png';
import gradientAvatar from './assets/gradient_avatar.png';
import jazzicon from './assets/jazzicon.png';

export const useExplorerSettingsModalStyles = makeStyles()(theme => ({
  modalContent: {
    padding: theme.spacing(5),
    maxWidth: 600,
  },
  inputLabelWrap: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  inputLabel: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  questionIcon: {
    width: 20,
    height: 20,
    color: theme.palette.text.secondary,
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(5),
  },

  customRadio: {
    display: 'block',
    width: 24,
    height: 24,
    border: `2px solid ${theme.palette.divider}`,
    borderRadius: 50,
    transition: 'all 300ms',
    background: 'no-repeat 50% 50%',
    backgroundSize: 'cover',
  },
  customRadioActive: {
    borderColor: theme.palette.primary.main,
  },
  light: {
    backgroundImage:
      'linear-gradient(154deg, rgb(239, 239, 239) 50%, rgba(255, 255, 255, 0) 330.86%)',
  },
  dim: {
    backgroundImage:
      'linear-gradient(152deg, rgb(35, 43, 55) 50%, rgba(255, 255, 255, 0) 290.71%)',
  },
  midnight: {
    backgroundImage:
      'linear-gradient(148deg, rgb(27, 63, 113) 50%, rgba(255, 255, 255, 0) 312.35%)',
  },
  dark: {
    backgroundImage:
      'linear-gradient(161deg, rgb(0, 0, 0) 9.37%, rgb(56, 56, 56) 92.52%)',
  },
  github: {
    backgroundImage: `url(${github})`,
  },
  jazzicon: {
    backgroundImage: `url(${jazzicon})`,
  },
  gradientAvatar: {
    backgroundImage: `url(${gradientAvatar})`,
  },
  blockie: {
    backgroundImage: `url(${blockies})`,
  },
}));
