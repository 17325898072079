/* eslint-disable camelcase */
import { Web3Address } from '@ankr.com/raas-types';
import BigNumber from 'bignumber.js';

import {
  IExplorerSettingsApi,
  IExplorerSettingsPayload,
  IExplorerSettingsWrap,
} from './screens/DeployRollup/components/ExplorerSettingsModal/const';

export const GRADE = {
  testnet: 'testnet',
  mainnet: 'mainnet',
};
export type GRADE = (typeof GRADE)[keyof typeof GRADE];

export const SEQUENCER_MANAGEMENT_TYPE = {
  product: 'product',
  self: 'self',
};
export type SEQUENCER_MANAGEMENT_TYPE =
  (typeof SEQUENCER_MANAGEMENT_TYPE)[keyof typeof SEQUENCER_MANAGEMENT_TYPE];

export const GAS_TOKEN = {
  eth: 'eth',
  custom: 'custom',
};
export type GAS_TOKEN = (typeof GAS_TOKEN)[keyof typeof GAS_TOKEN];

export const DECLARED_TOKEN = {
  ...GAS_TOKEN,
  eth_holesky: 'eth_holesky',
  ankreth: 'ankreth',
  frax: 'frax',
  frxeth: 'frxeth',
};
export type DECLARED_TOKEN =
  (typeof DECLARED_TOKEN)[keyof typeof DECLARED_TOKEN];

export const DECLARED_CHAIN = {
  eth: 'eth',
  ethereum: 'ethereum',
  faxtal: 'fraxtal',
  // TODO: need to change on the backend for l1_chain eth_holesky -> holesky and eth -> ethereum
  eth_holesky: 'eth_holesky',
  holesky: 'holesky',
};
export type DECLARED_CHAIN =
  (typeof DECLARED_CHAIN)[keyof typeof DECLARED_CHAIN];

export const ROLLUP_STATUS = {
  initializing: 'initializing',
  pending_deposit: 'pending_deposit',
  deploying_nodes: 'deploying_nodes',
  deploying_bridge: 'deploying_bridge',
  unpaid: 'unpaid',
  pending: 'pending',
  deployed: 'deployed',
  failed: 'failed',
  expired: 'expired',
};
export type ROLLUP_STATUS = (typeof ROLLUP_STATUS)[keyof typeof ROLLUP_STATUS];

export const DEPLOYMENT_TYPE = {
  arbitrum: 'arbitrum',
  optimism: 'optimism',
};
export type DEPLOYMENT_TYPE =
  (typeof DEPLOYMENT_TYPE)[keyof typeof DEPLOYMENT_TYPE];

export interface IStackItemApi {
  description: string;
  // TODO: remove it when the backend will provide keys for all Stacks
  icon?: string;
  key: string;
  title: string;
  uuid: string;
  coming_soon?: boolean;
  mainnet_coming_soon?: boolean;
}

export interface IStackItem {
  description: string;
  key: string;
  title: string;
  uuid: string;
  comingSoon?: boolean;
  mainnetComingSoon?: boolean;
}

export interface IDALItemApi {
  description: string;
  // TODO: remove it when the backend will provide keys for all DALs
  icon?: string;
  key: string;
  title: string;
  uuid: string;
  coming_soon?: boolean;
  supports_custom_gas_token?: boolean;
}

export interface IDALItem {
  description: string;
  key: string;
  title: string;
  uuid: string;
  comingSoon?: boolean;
  supportsCustomGasToken?: boolean;
}

interface IPlanSettingItem {
  description: string;
  key: string;
  price: BigNumber;
  title: string;
  uuid: string;
  value: string | number | boolean;
}

interface IFundRequestItem {
  address?: Web3Address;
  amount: BigNumber;
  token: string;
}

export interface IDepositItem {
  fundRequests: IFundRequestItem[];
  isCustom: boolean;
  parsedForUI: {
    amountsWithToken: string[];
    address?: Web3Address;
  };
}

export interface IDepositsForPlan {
  default?: IDepositItem;
  custom?: IDepositItem;
}

interface IFundRequestItemApi {
  address?: string;
  amount: string;
  token: string;
}

export interface IDepositItemApi {
  fund_requests: IFundRequestItemApi[] | null;
  is_custom: boolean;
}

export interface IDetailsBridgeItem {
  contracts: string;
  bridgeUrl?: string;
}

interface IDetailsItem {
  blockHeight?: number;
  rpcEndpoints?: string[];
  bridge?: IDetailsBridgeItem;
  explorerUrl?: string;
}

interface IDetailsBridgeItemApi {
  contracts: string;
  bridge_url?: string;
}

interface IDetailsItemApi {
  block_height?: number;
  bridge?: IDetailsBridgeItemApi;
  explorer_url?: string;
  rpc_url?: string | string[];
}

export interface IPlanItemApi {
  uuid: string;
  title: string;
  description: string;
  price: number;
  deposits: IDepositItemApi[] | null;
  stack: IStackItemApi;
  grade: GRADE;
  settings?: IPlanSettingItem[];
  optional_settings?: null | IPlanSettingItem[];
}

export interface IPlanItem {
  title: string;
  uuid: string;
  description: string;
  grade: GRADE;
  price: BigNumber;
  deposits?: IDepositsForPlan;
  stack: IStackItem;
  settings?: IPlanSettingItem[];
  optionalSettings?: null | IPlanSettingItem[];
}

export interface IDeployRollupFormGeneralPayload {
  stack?: string;
  grade?: GRADE;
  dataAvailabilityLayer?: string;
}

export interface IDeployRollupFormSequencerPayload {
  sequencerManagementType?: SEQUENCER_MANAGEMENT_TYPE;
  sequencerAddress?: Web3Address;
  sequencerUrl?: string;
}

export interface IDeployRollupFormConfigurationPayload {
  chainId?: string | number;
  networkName?: string;
  gasToken?: DECLARED_TOKEN;
  gasTokenAddress?: Web3Address;
  optionalSettings?: string[];
}

export interface IDeployRollupFormSignaturePayload {
  signature?: string;
  userAddress?: Web3Address;
}

export interface IDeployRollupFormPlanPayload {
  planUuid?: string;
}

export interface IDeployRollupFormPayload
  extends IDeployRollupFormGeneralPayload,
    IDeployRollupFormSequencerPayload,
    IDeployRollupFormConfigurationPayload,
    IDeployRollupFormSignaturePayload,
    IDeployRollupFormPlanPayload,
    IExplorerSettingsWrap {}

export const deployRollupInitialState: IDeployRollupFormPayload = {
  planUuid: '',
  stack: '',
  grade: '',
  dataAvailabilityLayer: '',
  sequencerManagementType: SEQUENCER_MANAGEMENT_TYPE.product,
  sequencerAddress: '',
  sequencerUrl: '',
  chainId: '',
  networkName: '',
  gasToken: '',
  gasTokenAddress: '',
  optionalSettings: [],
  explorerSettings: undefined,
};

export interface IDeployRollupFormApi {
  uuid?: string;
  chain_id?: number;
  data_availability_layer_uuid?: string;
  gas_token?: DECLARED_TOKEN;
  gas_token_address?: Web3Address;
  l1_chain?: string;
  network_name?: string;
  optional_settings?: string[];
  plan_uuid?: string;
  sequencer_address?: Web3Address;
  sequencer_management_type?: SEQUENCER_MANAGEMENT_TYPE;
  sequencer_url?: string;
  signature?: string;
  user_address?: Web3Address;
  explorer_settings?: IExplorerSettingsApi;
}

interface IDeployRollupSubscribeResponseApi {
  payment_intent_id?: string;
  url?: string;
}

export interface IDeployRollupResponseApi {
  deployment?: IDeployRollupFormApi;
  subscription?: IDeployRollupSubscribeResponseApi;
}

export interface IRollupDeployedApi {
  created_at: string;
  paid_until: string;
  uuid: string;
  plan: IPlanItemApi;
  deposits: IDepositItemApi[] | null;
  optional_settings: null | string[];
  status: ROLLUP_STATUS;
  deployment_type?: DEPLOYMENT_TYPE;
  sequencer_management_type: SEQUENCER_MANAGEMENT_TYPE;
  sequencer_address?: Web3Address;
  sequencer_url?: string;
  chain_id: number;
  network_name: string;
  gas_token: DECLARED_TOKEN;
  gas_token_address?: Web3Address;
  l1_chain: string;
  data_availability_layer?: IDALItemApi;
  extendable: boolean;
  details?: IDetailsItemApi;
  explorer_settings?: IExplorerSettingsApi;
}

export interface IRollupDeployed {
  createdAt: Date;
  paidUntil?: Date;
  uuid: string;
  plan: IPlanItem;
  deposits?: IDepositItem;
  optionalSettings?: null | string[];
  status: ROLLUP_STATUS;
  deploymentType?: DEPLOYMENT_TYPE;
  sequencerManagementType?: SEQUENCER_MANAGEMENT_TYPE;
  sequencerAddress?: Web3Address;
  sequencerUrl?: string;
  chainId: number;
  networkName: string;
  gasToken: DECLARED_TOKEN;
  gasTokenAddress?: Web3Address;
  dataAvailabilityLayer?: IDALItem;
  extendable: boolean;
  l1Chain?: string;
  details?: IDetailsItem;
  explorerSettings?: IExplorerSettingsPayload;
}
