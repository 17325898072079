import React, { JSX, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AppModal, GradientColorPicker } from '@ankr.com/raas-ui';
import { isValidWebsiteUrl, tCommon } from '@ankr.com/raas-utils';
import { Question, TextField } from '@ankr.com/ui';
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material';

import { DialogId } from '../../../../../common/actions/openDialog';
import { useDialog } from '../../../../../common/hooks/useDialog';
import { useTranslation } from '../../../../../i18n';
import { useDispatchRollupDeployData } from '../../hooks/useDispatchRollupDeployData';
import { useRollupDeployState } from '../../hooks/useRollupDeployState';
import {
  EXPLORER_SETTINGS_ADDRESS_ICON_TYPE,
  EXPLORER_SETTINGS_COLOR_THEME,
  EXPLORER_SETTINGS_NAVIGATION_LAYOUT,
  IExplorerSettingsPayload,
} from './const';
import { explorerSettingsTranslation } from './translation';
import { useExplorerSettingsModalStyles } from './useExplorerSettingsModalStyles';

export function ExplorerSettingsModal(): JSX.Element {
  const { classes, cx } = useExplorerSettingsModalStyles();

  const { keys, t } = useTranslation(explorerSettingsTranslation);

  const { explorerSettings } = useRollupDeployState();

  const { dispatchData } = useDispatchRollupDeployData();

  const {
    open: explorerSettingsOpen,
    handleClose: handleExplorerSettingsClose,
  } = useDialog(DialogId.ExplorerSettings);

  const { control, handleSubmit } = useForm<IExplorerSettingsPayload>({
    defaultValues: {
      addressIcon:
        explorerSettings?.addressIcon ??
        EXPLORER_SETTINGS_ADDRESS_ICON_TYPE.github,
      theme: explorerSettings?.theme ?? EXPLORER_SETTINGS_COLOR_THEME.light,
      navigation:
        explorerSettings?.navigation ??
        EXPLORER_SETTINGS_NAVIGATION_LAYOUT.vertical,
      logo: explorerSettings?.logo ?? '',
      logoDark: explorerSettings?.logoDark ?? '',
      icon: explorerSettings?.icon ?? '',
      iconDark: explorerSettings?.iconDark ?? '',
      background: explorerSettings?.background ?? 'rgb(53, 109, 243)',
      backgroundDark: explorerSettings?.backgroundDark ?? 'rgb(53, 109, 243)',
      textColor: explorerSettings?.textColor ?? 'rgb(255, 255, 255)',
      textColorDark: explorerSettings?.textColorDark ?? 'rgb(255, 255, 255)',
      ogImage: explorerSettings?.ogImage ?? '',
    },
  });

  const onSubmit = useCallback(
    (payload: IExplorerSettingsPayload) => {
      dispatchData({ explorerSettings: payload });
      handleExplorerSettingsClose();
    },
    [dispatchData, handleExplorerSettingsClose],
  );

  const urlInputValidation = useCallback((value: string | undefined) => {
    if (value) {
      if (isValidWebsiteUrl(value)) {
        return true;
      }
      if (!isValidWebsiteUrl(value)) {
        return tCommon('validation.invalid-url');
      }
    }
  }, []);

  return (
    <AppModal
      open={explorerSettingsOpen}
      onClose={handleExplorerSettingsClose}
      title={t(keys.title)}
      subtitle={t(keys.subtitle)}
      classes={{ paper: classes.modalContent }}
      sideRight
    >
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <Box mb={8}>
          <Typography variant="subtitle2" className={classes.inputLabel}>
            {t(keys.theme.label)}
            <Tooltip title={t(keys.theme.tooltip)}>
              <Question className={classes.questionIcon} />
            </Tooltip>
          </Typography>
          <Controller
            name="theme"
            control={control}
            render={({ field }) => (
              <Box className={classes.radioGroup}>
                {Object.entries(EXPLORER_SETTINGS_COLOR_THEME).map(
                  ([key, value]) => {
                    return (
                      <Box
                        component="label"
                        key={`theme_${key}`}
                        className={cx(
                          classes.customRadio,
                          classes[key as keyof typeof classes],
                          field.value === value && classes.customRadioActive,
                        )}
                      >
                        <input
                          {...field}
                          type="radio"
                          checked={field.value === value}
                          value={value}
                          hidden
                        />
                      </Box>
                    );
                  },
                )}
              </Box>
            )}
          />
        </Box>

        <Box mb={8}>
          <Typography variant="subtitle2" className={classes.inputLabel}>
            {t(keys.addressIcon.label)}
          </Typography>
          <Controller
            name="addressIcon"
            control={control}
            render={({ field }) => (
              <Box className={classes.radioGroup}>
                {Object.entries(EXPLORER_SETTINGS_ADDRESS_ICON_TYPE).map(
                  ([key, value]) => {
                    return (
                      <Box
                        component="label"
                        key={`addressIcon_${key}`}
                        className={cx(
                          classes.customRadio,
                          classes[key as keyof typeof classes],
                          field.value === value && classes.customRadioActive,
                        )}
                      >
                        <input
                          {...field}
                          type="radio"
                          checked={field.value === value}
                          value={value}
                          hidden
                        />
                      </Box>
                    );
                  },
                )}
              </Box>
            )}
          />
        </Box>

        <Box mb={8}>
          <Typography variant="subtitle2" className={classes.inputLabel}>
            {t(keys.navigation.label)}
          </Typography>
          <Controller
            name="navigation"
            control={control}
            render={({ field }) => (
              <RadioGroup
                name="navigation"
                className={classes.radioGroup}
                value={field.value}
              >
                {Object.entries(EXPLORER_SETTINGS_NAVIGATION_LAYOUT).map(
                  ([key, value]) => {
                    return (
                      <FormControlLabel
                        key={`navigation_${key}`}
                        control={
                          <Radio {...field} value={value} disableFocusRipple />
                        }
                        label={t(
                          keys.navigation[key as keyof typeof keys.navigation],
                        )}
                      />
                    );
                  },
                )}
              </RadioGroup>
            )}
          />
        </Box>

        <Box mb={8}>
          <Controller
            name="logo"
            control={control}
            rules={{
              validate: v => urlInputValidation(v),
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                helperText={error?.message ?? t(keys.logo.hint)}
                error={!!error}
                onChange={onChange}
                value={value}
                fullWidth
                type="url"
                label={
                  <Box className={classes.inputLabelWrap}>
                    {t(keys.logo.label)}
                    <Tooltip title={t(keys.logo.tooltip)}>
                      <Question className={classes.questionIcon} />
                    </Tooltip>
                  </Box>
                }
              />
            )}
          />
        </Box>

        <Box mb={8}>
          <Controller
            name="logoDark"
            control={control}
            rules={{
              validate: v => urlInputValidation(v),
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                helperText={error?.message}
                error={!!error}
                onChange={onChange}
                value={value}
                fullWidth
                type="url"
                label={
                  <Box className={classes.inputLabelWrap}>
                    {t(keys.logoDark.label)}
                    <Tooltip title={t(keys.logoDark.tooltip)}>
                      <Question className={classes.questionIcon} />
                    </Tooltip>
                  </Box>
                }
              />
            )}
          />
        </Box>

        <Box mb={8}>
          <Controller
            name="icon"
            control={control}
            rules={{
              validate: v => urlInputValidation(v),
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                helperText={error?.message ?? t(keys.icon.hint)}
                error={!!error}
                onChange={onChange}
                value={value}
                fullWidth
                type="url"
                label={
                  <Box className={classes.inputLabelWrap}>
                    {t(keys.icon.label)}
                    <Tooltip title={t(keys.icon.tooltip)}>
                      <Question className={classes.questionIcon} />
                    </Tooltip>
                  </Box>
                }
              />
            )}
          />
        </Box>

        <Box mb={8}>
          <Controller
            name="iconDark"
            control={control}
            rules={{
              validate: v => urlInputValidation(v),
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                helperText={error?.message}
                error={!!error}
                onChange={onChange}
                value={value}
                fullWidth
                type="url"
                label={
                  <Box className={classes.inputLabelWrap}>
                    {t(keys.iconDark.label)}
                    <Tooltip title={t(keys.iconDark.tooltip)}>
                      <Question className={classes.questionIcon} />
                    </Tooltip>
                  </Box>
                }
              />
            )}
          />
        </Box>

        <Box mb={8}>
          <Controller
            name="background"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <GradientColorPicker
                {...field}
                helperText={error?.message ?? t(keys.searchBarBg.hint)}
                error={!!error}
                fullWidth
                label={
                  <Box className={classes.inputLabelWrap}>
                    {t(keys.searchBarBg.label)}
                    <Tooltip title={t(keys.searchBarBg.tooltip)}>
                      <Question className={classes.questionIcon} />
                    </Tooltip>
                  </Box>
                }
              />
            )}
          />
        </Box>

        <Box mb={8}>
          <Controller
            name="backgroundDark"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <GradientColorPicker
                {...field}
                helperText={error?.message}
                error={!!error}
                fullWidth
                label={
                  <Box className={classes.inputLabelWrap}>
                    {t(keys.searchBarBgDark.label)}
                  </Box>
                }
              />
            )}
          />
        </Box>

        <Box mb={8}>
          <Controller
            name="textColor"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <GradientColorPicker
                {...field}
                helperText={error?.message ?? t(keys.searchBarColor.hint)}
                error={!!error}
                fullWidth
                solidOnly
                label={
                  <Box className={classes.inputLabelWrap}>
                    {t(keys.searchBarColor.label)}
                    <Tooltip title={t(keys.searchBarColor.tooltip)}>
                      <Question className={classes.questionIcon} />
                    </Tooltip>
                  </Box>
                }
              />
            )}
          />
        </Box>

        <Box mb={8}>
          <Controller
            name="textColorDark"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <GradientColorPicker
                {...field}
                helperText={error?.message}
                error={!!error}
                fullWidth
                solidOnly
                label={
                  <Box className={classes.inputLabelWrap}>
                    {t(keys.searchBarColorDark.label)}
                  </Box>
                }
              />
            )}
          />
        </Box>

        <Box mb={8}>
          <Controller
            name="ogImage"
            control={control}
            rules={{
              validate: v => urlInputValidation(v),
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                helperText={error?.message ?? t(keys.ogImage.hint)}
                error={!!error}
                onChange={onChange}
                value={value}
                fullWidth
                type="url"
                label={t(keys.ogImage.label)}
              />
            )}
          />
        </Box>

        <Box display="flex" flexDirection="column" gap={3} mt={8}>
          <Button type="submit" size="large" fullWidth>
            {t(keys.submit)}
          </Button>
          <Button
            onClick={handleExplorerSettingsClose}
            size="large"
            fullWidth
            variant="outlined"
          >
            {t(keys.cancel)}
          </Button>
        </Box>
      </form>
    </AppModal>
  );
}
