import React, { JSX, useMemo } from 'react';
import {
  AppList,
  IAdditionalItemProps,
  IAppListColumn,
} from '@ankr.com/raas-ui';
import { tCommon } from '@ankr.com/raas-utils';
import { Box, Card, Typography } from '@mui/material';

import { useLocaleMemo, useTranslation } from '../../../../../i18n';
import { billingTranslation } from '../../../../translation';
import {
  IBillingHistoryData,
  useGetPaymentHistoryQuery,
} from './api/getPaymentHistory';
import { useBillingHistoryStyles } from './useBillingHistoryStyles';

interface IBillingHistoryProps {
  rollupUuid?: string;
}

interface IBillingHistoryPrepared
  extends IBillingHistoryData,
    IAdditionalItemProps {}

export function BillingHistory({
  rollupUuid,
}: IBillingHistoryProps): JSX.Element {
  const { classes } = useBillingHistoryStyles();

  const { keys, t } = useTranslation(billingTranslation);

  const { data: historyData, isFetching: isHistoryListFetching } =
    useGetPaymentHistoryQuery(
      { uuid: rollupUuid },
      {
        skip: !rollupUuid,
      },
    );

  const rollupListDataPrepared: IBillingHistoryPrepared[] | undefined =
    useMemo(() => {
      if (!historyData) {
        return undefined;
      }
      return historyData;
    }, [historyData]);

  const columns: IAppListColumn<IBillingHistoryData>[] = useLocaleMemo(
    () => [
      {
        id: 'dateColumn',
        className: classes.dateColumn,
        header: (
          <Box className={classes.headerContent}>{t(keys.history.date)}</Box>
        ),
        renderCell: item => (
          <Typography variant="body2" component={Box} className={classes.value}>
            {item.createdAt
              ? tCommon('datetime.medium-short', { value: item.createdAt })
              : tCommon('common.empty-placeholder')}
          </Typography>
        ),
      },
      {
        id: 'typeColumn',
        className: classes.typeColumn,
        header: (
          <Box className={classes.headerContent}>{t(keys.history.type)}</Box>
        ),
        renderCell: item => (
          <Typography variant="body2" component={Box} className={classes.value}>
            {item.symbol ? item.symbol : tCommon('common.empty-placeholder')}
          </Typography>
        ),
      },
      {
        id: 'amountColumn',
        className: classes.amountColumn,
        header: (
          <Box className={classes.headerContent}>{t(keys.history.amount)}</Box>
        ),
        renderCell: item => {
          const valueString =
            item.symbol === 'USD'
              ? tCommon('number.price', { value: item.amount })
              : tCommon('number.with-currency-fixed-2', {
                  value: item.amount,
                  symbol: item.symbol,
                });

          return (
            <Typography
              variant="body2"
              component={Box}
              className={classes.value}
            >
              {item.amount ? valueString : tCommon('common.empty-placeholder')}
            </Typography>
          );
        },
      },
    ],
    [
      classes.amountColumn,
      classes.dateColumn,
      classes.headerContent,
      classes.typeColumn,
      classes.value,
      keys.history.amount,
      keys.history.date,
      keys.history.type,
      t,
    ],
  );

  return (
    <Box>
      <Box className={classes.titleWrap}>
        <Typography variant="h6">{t(keys.history.title)}</Typography>
      </Box>
      <Card className={classes.card}>
        <AppList
          isLoading={isHistoryListFetching}
          items={rollupListDataPrepared ?? []}
          columns={columns}
          compactView
          getKey={item => item.uuid}
        />
      </Card>
    </Box>
  );
}
