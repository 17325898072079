import { queryFnNotifyWrapper } from '@ankr.com/raas-ui';
import { utils } from 'web3';

import { cacheTags, Gateways, mainApi } from '../../../../api';
import {
  GRADE,
  IDeployRollupFormApi,
  IDeployRollupFormPayload,
  IDeployRollupResponseApi,
  SEQUENCER_MANAGEMENT_TYPE,
} from '../../../RollupConst';
import { mapExplorerSettingsToApi } from '../components/ExplorerSettingsModal/utils/mapExplorerSettingsToApi';

export const { usePostRollupDeployMutation } = mainApi.injectEndpoints({
  endpoints: build => ({
    postRollupDeploy: build.mutation<
      IDeployRollupResponseApi,
      IDeployRollupFormPayload
    >({
      queryFn: queryFnNotifyWrapper<
        IDeployRollupFormPayload,
        never,
        IDeployRollupResponseApi
      >(async payload => {
        const mappedPayload: IDeployRollupFormApi = {
          plan_uuid: payload.planUuid,

          data_availability_layer_uuid: payload.dataAvailabilityLayer,

          sequencer_management_type:
            payload.grade === GRADE.testnet
              ? undefined
              : payload.sequencerManagementType,

          sequencer_address:
            payload.grade === GRADE.testnet ||
            payload.sequencerManagementType ===
              SEQUENCER_MANAGEMENT_TYPE.product
              ? undefined
              : payload.sequencerAddress?.trim(),

          sequencer_url:
            payload.grade === GRADE.testnet ||
            payload.sequencerManagementType ===
              SEQUENCER_MANAGEMENT_TYPE.product
              ? undefined
              : payload.sequencerUrl?.trim(),

          chain_id: parseInt(payload.chainId as string, 10),

          network_name: payload.networkName?.trim(),

          gas_token: payload.gasToken,

          gas_token_address: payload.gasTokenAddress
            ? utils.toChecksumAddress(payload.gasTokenAddress?.trim())
            : undefined,

          optional_settings: !payload.optionalSettings?.length
            ? undefined
            : payload.optionalSettings,

          signature: payload.signature,

          user_address: payload.userAddress?.trim(),

          explorer_settings: payload.explorerSettings
            ? mapExplorerSettingsToApi(payload.explorerSettings)
            : undefined,
        };

        const res = await Gateways.auth.api.post(
          '/api/v1/deployments',
          JSON.stringify(mappedPayload),
        );

        return { data: res.data };
      }),
      invalidatesTags: [cacheTags.rollups],
    }),
  }),
});
