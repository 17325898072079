/* eslint-disable camelcase */

export const EXPLORER_SETTINGS_ADDRESS_ICON_TYPE = {
  github: 'github',
  jazzicon: 'jazzicon',
  gradientAvatar: 'gradient_avatar',
  blockie: 'blockie',
};
export type EXPLORER_SETTINGS_ADDRESS_ICON_TYPE =
  (typeof EXPLORER_SETTINGS_ADDRESS_ICON_TYPE)[keyof typeof EXPLORER_SETTINGS_ADDRESS_ICON_TYPE];

export const EXPLORER_SETTINGS_COLOR_THEME = {
  light: 'light',
  dim: 'dim',
  midnight: 'midnight',
  dark: 'dark',
};
export type EXPLORER_SETTINGS_COLOR_THEME =
  (typeof EXPLORER_SETTINGS_COLOR_THEME)[keyof typeof EXPLORER_SETTINGS_COLOR_THEME];

export const EXPLORER_SETTINGS_NAVIGATION_LAYOUT = {
  vertical: 'vertical',
  horizontal: 'horizontal',
};
export type EXPLORER_SETTINGS_NAVIGATION_LAYOUT =
  (typeof EXPLORER_SETTINGS_NAVIGATION_LAYOUT)[keyof typeof EXPLORER_SETTINGS_NAVIGATION_LAYOUT];

export interface IExplorerSettingsPayload {
  addressIcon?: EXPLORER_SETTINGS_ADDRESS_ICON_TYPE;
  theme?: EXPLORER_SETTINGS_COLOR_THEME;
  navigation?: EXPLORER_SETTINGS_NAVIGATION_LAYOUT;
  logo?: string;
  logoDark?: string;
  icon?: string;
  iconDark?: string;
  background?: string;
  backgroundDark?: string;
  textColor?: string;
  textColorDark?: string;
  ogImage?: string;
}

export interface IExplorerSettingsWrap {
  explorerSettings?: IExplorerSettingsPayload;
}

export interface IExplorerSettingsApi {
  NEXT_PUBLIC_VIEWS_ADDRESS_IDENTICON_TYPE?: EXPLORER_SETTINGS_ADDRESS_ICON_TYPE;
  NEXT_PUBLIC_COLOR_THEME_DEFAULT?: EXPLORER_SETTINGS_COLOR_THEME;
  NEXT_PUBLIC_NAVIGATION_LAYOUT?: EXPLORER_SETTINGS_NAVIGATION_LAYOUT;
  NEXT_PUBLIC_NETWORK_LOGO?: string;
  NEXT_PUBLIC_NETWORK_LOGO_DARK?: string;
  NEXT_PUBLIC_NETWORK_ICON?: string;
  NEXT_PUBLIC_NETWORK_ICON_DARK?: string;
  NEXT_PUBLIC_HOMEPAGE_HERO_BANNER_CONFIG?: {
    background?: string[];
    text_color?: string[];
  };
  NEXT_PUBLIC_OG_IMAGE_URL?: string;
}
