import { Locale } from '../../../i18n';

export const accountInfoFormTranslation = {
  [Locale.en]: {
    title: 'Account Information',
    subtitle:
      'You are already signed in. Please give us more information about your company.',

    email: 'Email',
    emailHint:
      'You cannot change your email address because your account is linked to it',
    companyName: 'Company Name',
    companyInfo: 'Company Description',
    companyInfoHint:
      'Add additional important information about your company. Optional',
    companyTwitter: 'Company Twitter',
    twitterHint: 'Twitter account full address or @username. Optional',

    submitBtn: 'Confirm',
  },
};
